.floatingButton {
    position: fixed;
    bottom: clamp(0.75rem, 2vw, 1.5rem);
    right: clamp(0.75rem, 2vw, 1.5rem);
    padding: clamp(0.5rem, 1.2vw, 0.75rem) clamp(0.9rem, 2vw, 1.25rem);
    background: var(--royal-blue);
    color: var(--text-color-light);
    border: none;
    border-radius: 8px;
    font-size: clamp(1.5rem, 3vw, 2rem);
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease-in-out;
}

.floatingButton:hover {
    background: var(--highlight-color);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color-light);
    z-index: 1000;
}

.overlayContent {
    background: var(--bg-gradient);
    padding: 2rem;
    border-radius: 12px;
    text-align: center;
    max-width: 90%;
    width: 600px;
    max-height: 90%;
    overflow-y: auto;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.overlayHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.overlayTitle {
    font-size: 1.5rem;
    text-shadow: var(--text-shadow);
}

.closeButton {
    background: transparent;
    color: var(--accent-color);
    border: none;
    font-size: 1rem;
    cursor: pointer;
}

.closeButton:hover {
    color: var(--highlight-color);
}

.flyerGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.flyerCard {
    background: var(--border-color);
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    width: calc(33.33% - 1rem); 
    min-width: 150px; 
    max-width: 200px; 
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flyerCard:hover {
    transform: scale(1.05);
}

.flyerImage {
    width: 100%;
    height: auto;
    border-radius: 6px;
}

.flyerTitle {
    margin-top: 0.5rem;
    font-size: 0.9rem;
}

@media (max-width: 600px) {
    .flyerCard {
        width: calc(50% - 1rem); 
    }
}

@media (max-width: 400px) {
    .flyerCard {
        width: 100%; 
    }
}
