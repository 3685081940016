.homeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    color: var(--text-color-light);
}

.childContainer {
    width: 100%; 
}

.homeContainer > * {
    margin-bottom: 0.5rem;
}

.homeContainer > *:last-child {
    margin-bottom: 0;
}
