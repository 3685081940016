.directorContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem;
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .directorTextContainer {
    flex: 1;
    padding-right: 2rem;
  }
  
  .directorImageContainer {
    flex: 1;
    max-width: 50%;
  }
  
  .directorImage {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .directorTitle {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--text-color-light);
  }
  
  .directorDescription {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: var(--text-color-light);
  }
  
  @media (max-width: 768px) {
    .directorContainer {
      flex-direction: column;
    }
  
    .directorTextContainer {
      padding-right: 0;
      margin-bottom: 2rem;
    }
  
    .directorImageContainer {
      max-width: 100%;
    }
  
    .directorTitle {
      font-size: 1.5rem;
    }
  
    .directorDescription {
      font-size: 0.875rem;
    }
  }
  