.competitionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    color: var(--text-color-light);
    position: relative;
    width: 100%;
    min-height: 100vh; 
    padding: 2rem;
    overflow-y: auto; 
  }
  
  .competitionContent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }
  
  .textContainer {
    flex: 1;
    padding-right: 2rem;
    overflow-y: auto;
    max-height: 100vh; 
  }
  
  .imageContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .competitionImage {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 100vh; 
  }
  
  .competitionSection {
    margin-bottom: 2rem;
  }
  
  .competitionTitle {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--royal-blue);
  }
  
  .competitionDescription {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: var(--text-color-light);
  }
  
  .backButton {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background: none;
    border: none;
    color: var(--text-color-light);
    text-shadow: var(--text-shadow);
    cursor: pointer;
    font-size: 1rem;
    z-index: 10;
    transition: color 0.3s ease;
  }
  
  .backButton:hover {
    color: var(--border-color);
  }
  
  @media (max-width: 768px) {
    .competitionContent {
      flex-direction: column;
      align-items: center;
    }
  
    .textContainer {
      padding-right: 0;
      margin-bottom: 2rem;
      max-height: none; 
    }
  
    .competitionImage {
      max-height: none; 
    }
  
    .competitionTitle {
      font-size: 1.5rem;
    }
  
    .competitionDescription {
      font-size: 0.875rem;
    }
  }
  
  @media (max-width: 480px) {
    .competitionTitle {
      font-size: 1.25rem;
    }
  
    .competitionDescription {
      font-size: 0.75rem;
    }
  
    .backButton {
      font-size: 0.875rem;
    }
  }
  