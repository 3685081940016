.scheduleContainer {
  width: 100%;
  height: 100%;
  margin: 20px auto;
  overflow: hidden;
  position: relative;
  padding: 2em;
}

.scheduleTitle {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--text-color-light);
  text-shadow: var(--text-shadow);
  margin-bottom: 1em;
}

.scrollListContainer {
  position: relative;
}

.scrollListContainer::before,
.scrollListContainer::after {
  content: '';
  position: absolute;
  top: 0;
  width: 20px;
  height: 100%;
  pointer-events: none;
}

.scrollList {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding-bottom: 10px;
  margin: 0;
  list-style-type: none;
  scroll-behavior: smooth;
  gap: 1em;
}

.day {
  min-width: 300px;
  height: auto;
  margin-right: 1em;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px double var(--border-color);
  padding: 1.5em;
  box-shadow: var(--text-shadow);
}

.day:last-child {
  margin-right: 0;
}

.scrollList::-webkit-scrollbar {
  display: none;
}

.scrollList {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.bg {
  stroke: rgba(255, 255, 255, 0.3);
  opacity: 0.3;
}

circle {
  stroke-dashoffset: 0;
  stroke-width: 15%;
  fill: none;
}

.progress {
  position: relative;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
}

.indicator {
  stroke: var(--royal-blue);
}

.dayTitle {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: var(--highlight-color);
  text-shadow: var(--text-shadow);
}

.classesList {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.classItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.75rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.classTime,
.classRoom,
.className {
  flex: 1;
  text-align: left;
  padding: 0 0.5rem;
  color: var(--text-color-light);
  font-size: 1.2em;
}

.classTime {
  flex: 0 0 15%;
  text-align: left;
}

.classRoom {
  flex: 0 0 10%;
  text-align: center;
  color: var(--accent-color);
}

.className {
  flex: 1;
  text-align: right;
  color: var(--text-color-light);
}

@media (max-width: 768px) {
  .day {
    min-width: 250px;
    padding: 1em;
  }

  .dayTitle {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .classTime,
  .classRoom,
  .className {
    font-size: 1rem;
    padding: 0 0.25rem;
  }
}

@media (max-width: 480px) {
  .day {
    min-width: 200px;
    padding: 0.75em;
  }

  .dayTitle {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }

  .classTime,
  .classRoom,
  .className {
    font-size: 0.875rem;
    padding: 0 0.25rem;
  }
}
