.classesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem;
    background-color: var(--background-page);
    color: var(--text-color);
    max-width: 100%;
    margin: 0 auto;
}

.imageContainer {
    flex: 1;
    max-width: 50%;
}

.classesImage {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.textContainer {
    flex: 1;
    padding-left: 2rem;
    text-align: center;
}

.classesButton {
    font-size: 3rem;
    font-weight: bold;
    background-color: transparent;
    color: var(--text-color);
    border: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s;
}

.classesButton:hover {
    color: var(--border-color);
}

@media (max-width: 768px) {
    .classesContainer {
        flex-direction: column;
        align-items: center;
    }

    .textContainer {
        padding-left: 0;
        text-align: center;
        margin-bottom: 1rem;
    }

    .imageContainer {
        max-width: 100%;
    }

    .classesButton {
        width: 90%;
        font-size: 2rem;
    }
}

@media (max-width: 480px) {
    .classesButton {
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
    }
}
