.announcementContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--background-page);
    color: var(--text-color);
    border-bottom: 1.5px solid var(--text-color-highlight);
    border-top: 1.5px solid var(--text-color-highlight);
    max-width: 100%;
    margin: 0 auto;
}

.announcementImage {
    width: 50vw;
    height: auto;
    object-fit: cover;
}

.announcementButton {
    flex: 1;
    background-color: var(--text-color-highlight);
    color: var(--text-color);
    border: none;
    padding: 1rem 2rem;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    text-align: center;
    margin-left: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-decoration: none; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainText {
    font-size: 5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.subText {
    font-size: 2.25rem;
    font-weight: bold;
}

.announcementButton:hover {
    background-color: var(--text-color-hover);
    transform: translateY(-7px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .announcementContainer {
        flex-direction: column;
        align-items: center;
    }

    .announcementImage {
        width: 100vw;
        height: 50vh;
        margin-bottom: 1rem;
    }

    .announcementButton {
        width: 90%;
    }

    .mainText {
        font-size: 3rem;
    }

    .subText {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .announcementButton {
        font-size: 1.25rem;
        padding: 0.5rem 1rem;
    }

    .mainText {
        font-size: 2rem;
    }

    .subText {
        font-size: 1rem;
    }
}
