.contactContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
  }
  
  .leftContainer {
    width: 50%;
    background: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rightContainer {
    width: 50%;
    position: relative;
  }
  
  .backgroundImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .textContainer {
    text-align: center;
    padding: 1rem;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
  }
  
  .link {
    color: var(--text-color-light);
    font-size: 5rem;
    margin: -0.75rem 0;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .link:hover {
    color: var(--text-color-light);
  }
  
  .otherLinks {
    color: var(--border-color);
  }
  
  @media (max-width: 768px) {
    .contactContainer {
      flex-direction: column;
    }
  
    .leftContainer,
    .rightContainer {
      width: 100%;
      height: 50%;
    }
  
    .rightContainer {
      order: -1;
    }
  
    .link {
      font-size: 3rem;
      margin: -0.15em 0;
    }
  }
  
  @media (max-width: 480px) {
    .contactContainer {
      flex-direction: column;
    }
  
    .leftContainer,
    .rightContainer {
      width: 100%;
      height: 50%;
    }
  
    .rightContainer {
      order: -1;
    }
  
    .link {
      font-size: 2.5rem;
      margin: -0.15em 0;
    }
  }
  