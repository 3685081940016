.contactContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    color: var(--text-color-light);
    position: relative;
}

.childContainer {
    width: 100%; 
}

.contactContainer > * {
    margin-bottom: 0.5rem;
}

.contactContainer > *:last-child {
    margin-bottom: 0;
}

.backButton {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background: none;
    border: none;
    color: var(--text-color-light);
    text-shadow: var(--text-shadow);
    cursor: pointer;
    font-size: 1rem;
    z-index: 10;
    transition: color 0.3s ease;
}

.backButton:hover {
    color: var(--border-color);
}

@media screen and (max-width: 768px) {
    .backButton {
        font-size: 0.875rem;
    }
}
