.footerContainer {
  background: var(--background-color);
  color: var(--text-color-light);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 2em;
  position: relative;
}

.footerBorder {
  position: absolute;
  top: 0;
  left: 5%;
  width: 90%;
  border-top: 1px solid var(--border-color);
}

.footerSection {
  flex: 1 1 20em;
  margin: 1em;
  min-width: 200px;
}

.footerTitle {
  font-weight: bold;
  font-size: 2.2em;
  margin-bottom: 1em;
}

.footerList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footerListItem {
  margin-bottom: 0.5em;
}

.footerLink, .footerButton {
  color: var(--text-color-light);
  text-decoration: none;
  font-size: 1.6em;
  font-weight: bold;
  transition: color 0.3s;
  word-wrap: break-word; /* Ensure long text breaks to the next line */
  background: none; /* For button */
  border: none; /* For button */
  cursor: pointer; /* For button */
  padding: 0; /* For button */
  display: inline-block; /* Ensure it behaves like a link */
}

.footerLink:hover, .footerButton:hover {
  color: var(--text-color-light);
}

.otherLinks {
  color: var(--border-color);
}

.activeLink {
  color: var(--royal-blue);
  font-size: 1.6em;
  font-weight: bold;
  text-decoration: none;
}

.footerAddress {
  font-style: normal;
  font-size: 1em;
  transition: color 0.3s;
}

.footerAddress a {
  color: var(--text-color-light);
  text-decoration: none;
  transition: color 0.3s;
}

.footerAddress a:hover {
  color: var(--border-color);
}

.footerBottom {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 2em;
  padding-top: 1em;
}

.footerText {
  margin: 0;
  text-align: center;
  flex: 1 1 100%;
  font-size: 1em;
}

.backToTopContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.backToTop {
  color: var(--text-color-light);
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  margin: 1em 0;
  font-size: 1.2em;
}

.backToTop:hover {
  color: var(--border-color);
}

@media (max-width: 768px) {
  .footerSection {
    flex: 1 1 100%;
    text-align: center;
  }

  .footerTitle {
    font-size: 1.8em; /* Reduce font size for smaller screens */
  }

  .footerLink, .footerButton {
    font-size: 1.4em; /* Reduce font size for smaller screens */
  }

  .footerBottom {
    flex-direction: column;
    align-items: center;
  }

  .footerText {
    font-size: 0.9em; /* Adjust the font size of the footer text */
  }

  .backToTopContainer {
    justify-content: center;
    margin-top: 1em;
  }
}

@media (max-width: 480px) {
  .footerTitle {
    font-size: 1.6em; /* Further reduce font size for very small screens */
  }

  .footerLink, .footerButton {
    font-size: 1.2em; /* Further reduce font size for very small screens */
  }

  .footerText {
    font-size: 0.8em; /* Further adjust the font size of the footer text */
  }

  .backToTop {
    font-size: 1em; /* Adjust the font size of the back-to-top button */
  }
}
