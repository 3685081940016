.headerContainer {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  z-index: 1000;
}

.leftSection {
  display: flex;
  align-items: center;
}

.rightSection {
  display: flex;
  align-items: center;
}

.logo {
  color: var(--text-color-light);
  text-shadow: var(--text-shadow);
  text-decoration: none;
  font-size: 2em;
  font-weight: bold;
  margin-right: 1em;
}

.navLinks {
  display: flex;
  align-items: center;
}

.navLink {
  color: var(--text-color-light);
  text-shadow: var(--text-shadow);
  text-decoration: none;
  font-size: 1.5em;
  font-weight: bold;
  margin: 0 1em;
}

.navLink:hover,
.navLink.active {
  color: var(--border-color);
}

.icon {
  font-size: 1.5em;
  color: var(--text-color-light);
  text-shadow: var(--text-shadow);
  margin: 0 0.5em;
  cursor: pointer;
}

.icon:hover {
  color: var(--border-color);
  text-shadow: var(--text-shadow);
}

.mobileMenuIcon {
  display: none;
  font-size: 1.5em;
  color: var(--text-color-light);
  text-shadow: var(--text-shadow);
  cursor: pointer;
  margin-left: 1em;
}

.mobileMenuIcon:hover {
  color: var(--border-color);
  text-shadow: var(--text-shadow);
}

@media (max-width: 768px) {
  .navLinks {
    display: none;
  }

  .mobileMenuIcon {
    display: block;
  }
}

@media (max-width: 480px) {
  .headerContainer {
    padding: 0.5em 1em;
  }

  .logo {
    font-size: 1.5em;
  }

  .icon {
    font-size: 1.2em;
  }

  .mobileMenuIcon {
    font-size: 1.2em;
  }
}
