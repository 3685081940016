.datesContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    color: var(--text-color-light);
    position: relative;
    padding: 2rem;
}

.childContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.textContainer {
    flex: 1;
    padding: 1rem;
}

.datesTitle {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--text-color-light);
    text-shadow: var(--text-shadow);
    text-align: center;
}

.datesListContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
    overflow-x: auto;
}

.datesList {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dateItem {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--text-color-light);
}

.event {
    font-size: 1.2rem;
    color: var(--text-color-light);
}

.date {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--highlight-color);
}

.footerNote {
    margin-top: 2rem;
    font-size: 1rem;
    color: var(--text-color-light);
    text-align: center;
}

.imageContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.datesImage {
    width: 100%;
    height: auto;
    max-height: 100vh;
    object-fit: cover;
}

.backButton {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background: none;
    border: none;
    color: var(--text-color-light);
    text-shadow: var(--text-shadow);
    cursor: pointer;
    font-size: 1rem;
    z-index: 10;
    transition: color 0.3s ease;
}

.backButton:hover {
    color: var(--border-color);
}

@media screen and (max-width: 768px) {
    .childContainer {
        flex-direction: column;
    }

    .imageContainer {
        order: -1;
    }

    .datesTitle {
        font-size: 2rem;
    }

    .date, .event {
        font-size: 1rem;
    }

    .backButton {
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 480px) {
    .datesTitle {
        font-size: 1.75rem;
    }

    .date, .event {
        font-size: 0.875rem;
    }

    .backButton {
        font-size: 0.75rem;
    }
}
