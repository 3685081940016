.dressCodeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    color: var(--text-color-light);
    position: relative;
}

.childContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.textContainer {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
}

.imageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}

.dressCodeImage {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.dressCodeTitle {
    font-size: 2rem; 
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--text-color-light);
}

.classTitle {
    font-size: 1.5rem; 
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: var(--royal-blue);
}

.dressCodeDescription {
    font-size: 1rem; 
    margin-bottom: 1rem;
    color: var(--text-color-light);
}

.backButton {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background: none;
    border: none;
    color: var(--text-color-light);
    text-shadow: var(--text-shadow);
    cursor: pointer;
    font-size: 1rem;
    z-index: 10;
    transition: color 0.3s ease;
}

.backButton:hover {
    color: var(--border-color);
}

@media screen and (min-width: 769px) {
    .childContainer {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }

    .textContainer {
        flex: 1;
        padding-right: 2rem;
    }

    .imageContainer {
        flex: 1;
        padding-left: 2rem;
        margin: 0;
    }

    .dressCodeImage {
        max-width: 100%;
        height: auto;
        object-fit: contain;
    }
}

@media screen and (max-width: 768px) {
    .childContainer {
        flex-direction: column;
        height: auto;
    }

    .imageContainer {
        margin: 1rem 0;
    }

    .dressCodeTitle {
        font-size: 1.8rem; 
    }

    .classTitle {
        font-size: 1.4rem; 
    }

    .dressCodeDescription {
        font-size: 0.9rem; 
    }

    .backButton {
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 480px) {
    .childContainer {
        height: auto;
    }

    .dressCodeTitle {
        font-size: 1.6rem; 
    }

    .classTitle {
        font-size: 1.2rem; 
    }

    .dressCodeDescription {
        font-size: 0.8rem; 
    }

    .backButton {
        font-size: 0.8rem; 
    }
}
