.titleContainer {
  width: 100%;
  height: 100vh;
  background: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  width: 50vw;
  height: 100%;
  object-fit: cover;
  z-index: 0; 
}

.textContainer {
  z-index: 1; 
  text-align: left;
  padding: 1rem;
  width: 100%; 
  display: flex; 
  flex-direction: column; 
  align-items: flex-start; 
}

.years {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  color: var(--text-color-light);
  z-index: 1;
}

.name {
  font-size: 6rem;
  font-weight: bold;
  color: var(--royal-blue);
  text-shadow: var(--text-shadow);
  z-index: 1;
  text-align: left; 
}

@media (max-width: 768px) {
  .textContainer {
    align-items: center; 
    text-align: center; 
  }

  .years {
    font-size: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .name {
    font-size: 2.5rem;
    text-align: center; 
  }

  .backgroundImage {
    width: 100vw;
    height: 50%;
  }
}

@media (max-width: 480px) {
  .textContainer {
    align-items: center; 
    text-align: center;
  }

  .years {
    font-size: 0.8rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .name {
    font-size: 2rem;
    text-align: center; 
  }

  .backgroundImage {
    width: 100vw;
    height: 50%;
  }
}
