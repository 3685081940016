.missionContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem;
    background-color: var(--background-color);
    color: var(--text-color-light);
    text-align: left;
}

.textContainer {
    flex: 1;
    padding-right: 2rem;
}

.imageContainer {
    flex: 1;
}

.missionContent {
    max-width: 70vw;
    margin-bottom: 2rem;
}

.missionTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--text-color-light);
    padding-bottom: 0.5em;
    border-bottom: 2px solid var(--royal-blue);
}

.missionDescription {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: var(--text-color-light);
}

.missionImage {
    width: 100%;
    height: auto;
    object-fit: cover;
}

@media (max-width: 768px) {
    .missionContainer {
        flex-direction: column;
        text-align: center;
    }

    .textContainer {
        padding-right: 0;
    }

    .imageContainer {
        margin-top: 2rem;
    }

    .missionContent {
        max-width: 90vw;
    }
}

@media (max-width: 480px) {
    .missionTitle {
        font-size: 1.25rem;
    }

    .missionDescription {
        font-size: 0.875rem;
    }
}
