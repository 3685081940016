.contactContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.contactBackground {
    background-color: var(--background-color);
}

.enrollBackground {
    background-color: #32CD32;
}

.leftContainer {
    width: 50%;
    position: relative;
}

.backgroundImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.rightContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    overflow-y: auto;
}

.title {
    font-size: 2.5rem;
    color: var(--text-color-light);
    margin-bottom: 0.5rem;
}

.subtitle {
    font-size: 1.2rem;
    color: var(--text-color-light);
    margin-bottom: 2rem;
}

.contactForm {
    display: flex;
    flex-direction: column;
    background-color: rgba(28, 28, 28, 0.7);
    border-radius: 8px;
    padding: 2rem;
    box-shadow: var(--text-shadow);
    backdrop-filter: blur(10px);
    color: var(--text-color-light);
    overflow-y: auto;
    max-height: calc(100vh - 4rem); 
}

.formGroup {
    margin-bottom: 1.5rem;
}

.labelWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.label {
    font-size: 1rem;
    color: var(--text-color-light);
}

.error {
    font-size: 0.875rem;
    color: var(--royal-blue);
}

.inputField {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--text-color-gray);
    border-radius: 4px;
    background-color: rgba(28, 28, 28, 0.8);
    color: var(--text-color-light);
    font-size: 1rem;
    outline: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
}

.inputField::placeholder {
    color: var(--text-color-gray);
}

.inputField:focus {
    background-color: rgba(28, 28, 28, 0.9);
    border: 1px solid var(--royal-blue);
    box-shadow: 0 0 5px var(--royal-blue);
}

.responseMessage {
    font-size: 1rem;
    color: var(--royal-blue);
    text-align: center;
    margin-bottom: 1.5rem;
}

.submitButtonWrapper {
    display: flex;
    justify-content: center;
}

.submitButton {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    background-color: var(--royal-blue);
    color: var(--text-color-light);
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin-top: 1rem;
    width: 200px;
}

.submitButton:hover {
    background-color: darken(var(--royal-blue), 10%);
    box-shadow: 0 0 10px var(--royal-blue);
}

.submitButton:disabled {
    background-color: lighten(var(--royal-blue), 20%);
    cursor: not-allowed;
}

@media screen and (max-width: 768px) {
    .contactContainer {
        flex-direction: column-reverse;
        height: auto; 
    }

    .leftContainer, .rightContainer {
        width: 100%;
        height: auto; 
    }

    .rightContainer {
        padding: 1rem;
    }

    .title {
        font-size: 2rem;
    }

    .subtitle {
        font-size: 1rem;
    }

    .inputField {
        padding: 0.5rem;
    }

    .submitButton {
        padding: 0.5rem 1rem;
        width: 150px;
    }
}

@media screen and (max-width: 480px) {
    .rightContainer {
        padding: 1rem;
    }

    .inputField {
        padding: 0.5rem;
    }

    .submitButton {
        padding: 0.5rem 1rem;
        width: 150px;
    }
}
