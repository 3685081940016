:root {
  --background-color: #000;
  --text-color-light: #ffffff;
  --text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4), 0px 0px 5px rgba(0, 0, 0, 0.15);
  --royal-blue: #0343FC; 
  --highlight-color: #FFD700; 
  --accent-color: #40E0D0;
  --border-color: rgba(255, 255, 255, 0.3);
  --bg-gradient: linear-gradient(145deg, #2e2e2e, #1c1c1c); 
}

* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
