.detailContainer {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    padding: 2em;
    background-color: var(--background-color);
  }
  
  .detailTitle {
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    color: var(--text-color-light);
    text-shadow: var(--text-shadow);
    margin-bottom: 1em;
  }
  
  .introText {
    text-align: center;
    font-size: 1.25rem;
    margin-bottom: 2em;
    color: var(--text-color-light);
  }
  
  .scrollListContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  
  .scrollList {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding-bottom: 10px;
    margin: 0;
    list-style-type: none;
    scroll-behavior: smooth;
    gap: 3em;
  }
  
  .classGroup {
    display: flex;
    flex-direction: row;
  }
  
  .classItem {
    min-width: 35vw;
    max-width: 35vw;
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-grow: 0;
    border: 5px double var(--royal-blue);
    padding: 0;
    box-shadow: var(--text-shadow);
  }
  
  .classImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .classTitle {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: var(--royal-blue);
    padding: 1em;
  }
  
  .classAge, .classDescription {
    font-size: 1rem;
    color: var(--text-color-light);
    padding: 0 1em;
  }
  
  .classAge {
    margin-bottom: 0.5rem;
  }
  
  .outroText {
    text-align: center;
    font-size: 1.25rem;
    margin-top: 2em;
    color: var(--text-color-light);
  }
  
  .backButton {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background: none;
    border: none;
    color: var(--text-color-light);
    text-shadow: var(--text-shadow);
    cursor: pointer;
    font-size: 1rem;
    z-index: 10;
    transition: color 0.3s ease;
  }
  
  .backButton:hover {
    color: var(--border-color);
  }
  
  .bg {
    stroke: rgba(255, 255, 255, 0.3);
    opacity: 0.3;
  }
  
  circle {
    stroke-dashoffset: 0;
    stroke-width: 15%;
    fill: none;
  }
  
  .progress {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-90deg);
    width: 3rem;
    height: 3rem;
  }
  
  .indicator {
    stroke: var(--royal-blue);
  }
  
  @media screen and (max-width: 768px) {
    .classItem {
      min-width: 45vw;
      max-width: 45vw;
    }
  
    .classTitle {
      font-size: 1.5rem;
    }
  
    .classAge, .classDescription {
      font-size: 0.875rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .classItem {
      min-width: 55vw;
      max-width: 55vw;
    }
  
    .classTitle {
      font-size: 1.25rem;
    }
  
    .classAge, .classDescription {
      font-size: 0.75rem;
    }
  }
  