.aboutUsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem;
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .aboutUsImageContainer {
    flex: 1;
    max-width: 50%;
    padding-right: 2rem;
  }
  
  .aboutUsImage {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .aboutUsTextContainer {
    flex: 1;
  }
  
  .aboutUsTitle {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--text-color-light);
  }
  
  .aboutUsDescription {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: var(--text-color-light);
  }
  
  @media (max-width: 768px) {
    .aboutUsContainer {
      flex-direction: column;
    }
  
    .aboutUsImageContainer {
      padding-right: 0;
      margin-bottom: 2rem;
      max-width: 100%;
    }
  
    .aboutUsTitle {
      font-size: 1.5rem;
    }
  
    .aboutUsDescription {
      font-size: 0.875rem;
    }
  }
  